import { createSlice } from '@reduxjs/toolkit';
import axiosApiInstance from '../../AxiosApiInstance';
const API_URL = process.env.NEXT_PUBLIC_API_URL;
import Notification from '../../../src/components/ErrorNotification/ErrorNotification';
import Papa from 'papaparse';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { EnglishConvertServices } from '@/components/DateCheck';
import { userService } from 'services';
dayjs.extend(utc);
dayjs.extend(timezone);

function deepMergeObjects(target, source) {
    // Iterate through all properties in the source object
    for (const key in source) {
        if (source.hasOwnProperty(key)) {
            // Check if the property is an object (nested object)
            if (source[key] instanceof Object && !Array.isArray(source[key])) {
                // If the property is an object, and exists in the target, merge recursively
                if (
                    target.hasOwnProperty(key) &&
                    target[key] instanceof Object &&
                    !Array.isArray(target[key])
                ) {
                    deepMergeObjects(target[key], source[key]);
                } else {
                    // If the property is an object, but doesn't exist in the target, create it
                    target[key] = deepClone(source[key]);
                }
            } else {
                // If the property is not an object, or is an array, overwrite the target property
                target[key] = source[key];
            }
        }
    }

    return target;
}

// Helper function to deep clone an object
function deepClone(obj) {
    if (obj === null || typeof obj !== 'object') {
        return obj;
    }

    if (Array.isArray(obj)) {
        const arrCopy = [];
        for (let i = 0; i < obj.length; i++) {
            arrCopy[i] = deepClone(obj[i]);
        }
        return arrCopy;
    }

    const objCopy = {};
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            objCopy[key] = deepClone(obj[key]);
        }
    }

    return objCopy;
}
let initialState = {
    selectedTab: 'accidents',
    groupBy: '',
    filterData: {
        data: [],
        pagination: {
            totalPages: 1,
            page: 1,
            currentResults: 0,
            totalResults: 0,
            resultsPerPage: 10,
        },
        loading: true,
    },
    reportVehiclesSearchValue: null,
    reportDriversSearchValue: null,
    filter: {
        period: {
            type: 'today',
            from: dayjs().startOf('day').format('YYYY-MM-DD HH:mm'),
            to: dayjs().endOf('day').format('YYYY-MM-DD HH:mm'),
        },
        vehiclesGroups: {
            data: [],
            selected: [],
            pagination: {
                totalPages: 0,
                page: 1,
            },
        },
        vehicles: {
            data: [],
            selected: [],
            pagination: {
                totalPages: 0,
                page: 1,
            },
        },
        drivers: {
            data: [],
            selected: [],
            pagination: {
                totalPages: 0,
                page: 1,
            },
        },
        driversGroups: {
            data: [],
            selected: [],
            pagination: {
                totalPages: 0,
                page: 1,
            },
        },
        make: {
            data: ['toyota', 'nissan', 'hino', 'chery', 'ford', 'mazda', 'hyundai', 'gmc', 'isuzu'],
            selected: [],
        },
        model: {
            data: [
                'corolla',
                'prius',
                'avalon',
                'hilux',
                'coaster',
                'hiace',
                'camry',
                'cross',
                'highlander',
                'fortuner v6',
                'land cruiser',
                'yaris',
                'c-hr',
                'rav 4',
                'leaf',
                'tiida',
                'murano',
                'Hino 300',
                'Hino 500',
                'arrizo 6',
                'tiggo 6',
                'tiggo 8',
                'taurus',
                'mazda 6',
                'accent',
                'sonata',
                'elantra',
                'azera',
                'yukon',
                'yukon denali',
                'N 9.5t',
                'N 6.5t',
                'urvan',
            ],
            selected: [],
        },
        year: [1950, dayjs().year()],
        year_range: [1950, dayjs().year()],
        harshEventsType: {
            data: ['braking', 'cornering', 'acceleration'],
            selected: [],
        },
        badBehavireDriver: false,
        excessiveMileage: false,
        dtcType: {
            data: ['Interior', 'Exterior', 'Electrical', 'Powertrain', 'Wheel And Tire'],
            selected: [],
        },
        maxSpeed: [0, 300],
    },
};
const getCurrentLocation = () => {
    if (typeof window !== 'undefined') {
        const queryString = window.location.search;

        const params = new URLSearchParams(queryString);

        // Convert the URLSearchParams object to an object
        const queryObject: any = {};
        params.forEach((value, key) => {
            queryObject[key] = value;
        });
        if (!queryObject?.state) {
            return initialState;
        }
        const decodedString = decodeURIComponent(queryObject?.state);
        const objectFilter = JSON.parse(decodedString);
        const state = deepMergeObjects({ ...initialState }, objectFilter);

        return state;
    }
    return initialState;
};
initialState = getCurrentLocation();

const ReportsSlice = createSlice({
    name: 'Reports',
    initialState: initialState,
    reducers: {
        setSelectedTab: (state, param) => {
            state.reportVehiclesSearchValue = '';
            state.reportDriversSearchValue = '';
            state.selectedTab = param?.payload;
        },
        setProblemsAffecting: (state, param) => {
            const payload = param?.payload;

            state.groupBy = payload?.groupBy;
            state.selectedTab = payload?.selectedTab;
            state.filter = {
                ...initialState.filter,
                excessiveMileage: payload?.filter?.excessiveMileage,
                badBehavireDriver: payload?.filter?.badBehavireDriver,
                harshEventsType: {
                    ...initialState.filter.harshEventsType,
                    selected: payload?.filter?.harshEventsType?.selected || [],
                },
            };
        },
        setSelectedGroupBy: (state, param) => {
            state.groupBy = param?.payload;
        },
        initialReportFilterYear: (state, param) => {
            state.filter = {
                ...initialState.filter,
                vehicles: {
                    ...state.filter.vehicles,
                    selected: [],
                },
                drivers: {
                    ...state.filter.drivers,
                    selected: [],
                },
                driversGroups: {
                    ...state.filter.driversGroups,
                    selected: [],
                },
                vehiclesGroups: {
                    ...state.filter.vehiclesGroups,
                    selected: [],
                },
                harshEventsType: {
                    ...initialState.filter.harshEventsType,
                    selected: state?.filter?.harshEventsType?.selected || [],
                },
                period: state.filter.period,
                badBehavireDriver: state.filter.badBehavireDriver,
                excessiveMileage: state.filter.excessiveMileage,
                year: [param?.payload.min_year, param?.payload.max_year],
                year_range: [param?.payload.min_year, param?.payload.max_year],
            };
        },

        setFilterPeriod: (state, param) => {
            const { type, from, to } = param?.payload;

            if (type == 'custom') {
                let period = {
                    ...state.filter.period,
                    type,
                };
                if (from) period['from'] = dayjs(from).startOf('day').format('YYYY-MM-DD HH:mm');
                if (to) period['to'] = dayjs(to).endOf('day').format('YYYY-MM-DD HH:mm');
                state.filter.period = period;
            } else if (type == 'today') {
                state.filter.period = {
                    type,
                    from: dayjs().startOf('day').format('YYYY-MM-DD HH:mm'),
                    to: dayjs().endOf('day').format('YYYY-MM-DD HH:mm'),
                };
            } else if (type == 'lastWeek') {
                state.filter.period = {
                    type,
                    from: dayjs().subtract(7, 'day').startOf('day').format('YYYY-MM-DD HH:mm'),
                    to: dayjs().endOf('day').subtract(1, 'days').format('YYYY-MM-DD HH:mm'),
                };
            } else if (type == 'lastMonth') {
                state.filter.period = {
                    type,
                    from: dayjs().subtract(30, 'days').startOf('day').format('YYYY-MM-DD HH:mm'),
                    to: dayjs().subtract(1, 'days').endOf('day').format('YYYY-MM-DD HH:mm'),
                };
            } else if (type == 'lastYear') {
                state.filter.period = {
                    type,
                    from: dayjs().subtract(1, 'year').startOf('year').format('YYYY-MM-DD HH:mm'),
                    to: dayjs().subtract(1, 'year').endOf('year').format('YYYY-MM-DD HH:mm'),
                };
            }
        },
        setVehiclesGroups: (state, param) => {
            const data = param?.payload;
            state.filter.vehiclesGroups = {
                ...state.filter.vehiclesGroups,
                data: data?.docs,
                pagination: {
                    totalPages: data?.totalPages,
                    page: data?.page,
                },
            };
        },
        setReportVehiclesList: (state, param) => {
            const data = param?.payload;
            state.filter.vehicles = {
                ...state.filter.vehicles,
                data: data?.hits,
                pagination: {
                    totalPages: Math.ceil(data?.found / 10),
                    page: data?.page,
                },
            };
        },
        setReportDriversList: (state, param) => {
            const data = param?.payload;
            state.filter.drivers = {
                ...state.filter.drivers,
                data: data?.hits,
                pagination: {
                    totalPages: Math.ceil(data?.found / 10),
                    page: data?.page,
                },
            };
        },
        setDriversGroups: (state, param) => {
            const data = param?.payload;
            state.filter.driversGroups = {
                ...state.filter.driversGroups,
                data: data,
                pagination: {
                    totalPages: data?.totalPages,
                    page: data?.page,
                },
            };
        },
        setSelectedGroups: (state, param) => {
            const { type, selected } = param?.payload;
            state.filter[`${type}Groups`].selected = selected;
        },
        setReportFilterField: (state, param) => {
            const { key, value } = param?.payload;
            state.filter[key] = value;
        },
        resetField: (state, param) => {
            const field = param?.payload;
            state.filter[field].selected = [];
        },
        resetBooleanField: (state, param) => {
            const field = param?.payload;
            state.filter[field] = false;
        },
        resetFilter: (state, param) => {
            state.reportVehiclesSearchValue = '';
            state.reportDriversSearchValue = '';
            state.filterData = {
                data: [],
                pagination: {
                    totalPages: 1,
                    page: 1,
                    currentResults: 0,
                    totalResults: 0,
                    resultsPerPage: 10,
                },
                loading: false,
            };
            state.filter = {
                ...initialState?.filter,
                year: [state?.filter.year[0], state?.filter.year[1]],
                year_range: [state?.filter.year_range[0], state?.filter.year_range[1]],
                badBehavireDriver: false,
                excessiveMileage: false,
                vehiclesGroups: {
                    ...state.filter.vehiclesGroups,
                    selected: [],
                },
                driversGroups: {
                    ...state.filter.driversGroups,
                    selected: [],
                },
                vehicles: {
                    ...state.filter.vehicles,
                    selected: [],
                },
                drivers: {
                    ...state.filter.drivers,
                    selected: [],
                },
                harshEventsType: {
                    ...state.filter.harshEventsType,
                    selected: [],
                },
            };
        },
        setFilterData: (state, param) => {
            state.filterData = { ...state.filterData, ...param?.payload };
        },
        setReportVehiclesSearchValue: (state, param) => {
            state.reportVehiclesSearchValue = param?.payload;
        },
        setReportDriversSearchValue: (state, param) => {
            state.reportDriversSearchValue = param?.payload;
        },
    },
});

const getDataFilterAndQuery = (selectedTab: string, filter: any, groupBy: string) => {
    let dataFilter = {
        startFromTo: [filter?.period?.from, filter?.period?.to],
    };
    let query = '';
    if (selectedTab == 'accidents') {
        query += '/fleet-events';
        dataFilter['fleetEventType'] = 'accident';
        dataFilter['vehicleGroups'] = filter?.vehiclesGroups?.selected;
        dataFilter['vehicles'] = filter?.vehicles?.selected;
        dataFilter['make'] = filter?.make?.selected;
        dataFilter['models'] = filter?.model?.selected;
        dataFilter['years'] = filter?.year;
        dataFilter['driverGroups'] = filter?.driversGroups?.selected;
    }

    if (selectedTab == 'harsh_events') {
        query += '/fleet-events';
        dataFilter['fleetEventType'] = 'harsh';
        dataFilter['harshSubTypes'] = filter?.harshEventsType?.selected;
        dataFilter['groupBy'] = groupBy;

        if (groupBy == 'vehicle') {
            dataFilter['vehicleGroups'] = filter?.vehiclesGroups?.selected;
            dataFilter['vehicles'] = filter?.vehicles?.selected;
            dataFilter['make'] = filter?.make?.selected;
            dataFilter['models'] = filter?.model?.selected;
            dataFilter['years'] = filter?.year;
        } else if (groupBy == 'driver') {
            dataFilter['drivers'] = filter?.drivers?.selected;
            dataFilter['driverGroups'] = filter?.driversGroups?.selected;
        } else {
            dataFilter['vehicleGroups'] = filter?.vehiclesGroups?.selected;
            dataFilter['vehicles'] = filter?.vehicles?.selected;
            dataFilter['make'] = filter?.make?.selected;
            dataFilter['models'] = filter?.model?.selected;
            dataFilter['years'] = filter?.year;
            dataFilter['drivers'] = filter?.drivers?.selected;
            dataFilter['driverGroups'] = filter?.driversGroups?.selected;
        }
    }
    if (selectedTab == 'speeding') {
        if (groupBy == 'driver') {
            query += '/drivers/overspeeding';
            dataFilter['drivers'] = filter?.drivers?.selected;
            dataFilter['driverGroups'] = filter?.driversGroups?.selected;
        } else if (groupBy == 'vehicle') {
            query += '/vehicles/overspeeding';
            dataFilter['vehicleGroups'] = filter?.vehiclesGroups?.selected;
            dataFilter['vehicles'] = filter?.vehicles?.selected;
            dataFilter['make'] = filter?.make?.selected;
            dataFilter['models'] = filter?.model?.selected;
            dataFilter['years'] = filter?.year;
        } else {
            query += '/fleet-events';
            dataFilter['fleetEventType'] = 'over-speeding';
            dataFilter['vehicleGroups'] = filter?.vehiclesGroups?.selected;
            dataFilter['vehicles'] = filter?.vehicles?.selected;
            dataFilter['make'] = filter?.make?.selected;
            dataFilter['models'] = filter?.model?.selected;
            dataFilter['years'] = filter?.year;
            dataFilter['drivers'] = filter?.drivers?.selected;
            dataFilter['driverGroups'] = filter?.driversGroups?.selected;
            dataFilter['speeds'] = filter?.maxSpeed;
        }
    }

    if (selectedTab == 'mileage') {
        if (groupBy == 'vehicle') {
            query += '/vehicles/mileage';
            dataFilter['vehicleGroups'] = filter?.vehiclesGroups?.selected;
            dataFilter['vehicles'] = filter?.vehicles?.selected;
            dataFilter['make'] = filter?.make?.selected;
            dataFilter['models'] = filter?.model?.selected;
            dataFilter['years'] = filter?.year;
            dataFilter['excessiveMileage'] = filter?.excessiveMileage;
        } else if (groupBy == 'driver') {
            query += '/drivers/mileage';
            dataFilter['drivers'] = filter?.drivers?.selected;
            dataFilter['driverGroups'] = filter?.driversGroups?.selected;
            dataFilter['excessiveMileage'] = filter?.excessiveMileage;
        } else {
            query += '/default/mileage';
            dataFilter['vehicleGroups'] = filter?.vehiclesGroups?.selected;
            dataFilter['vehicles'] = filter?.vehicles?.selected;
            dataFilter['make'] = filter?.make?.selected;
            dataFilter['models'] = filter?.model?.selected;
            dataFilter['years'] = filter?.year;
            dataFilter['drivers'] = filter?.drivers?.selected;
            dataFilter['driverGroups'] = filter?.driversGroups?.selected;
        }
    }

    if (selectedTab == 'geofences') {
        query += '/fleet-events';
        dataFilter['fleetEventType'] = 'geoFence';
        dataFilter['vehicleGroups'] = filter?.vehiclesGroups?.selected;
        dataFilter['vehicles'] = filter?.vehicles?.selected;
        dataFilter['make'] = filter?.make?.selected;
        dataFilter['models'] = filter?.model?.selected;
        dataFilter['years'] = filter?.year;
        dataFilter['driverGroups'] = filter?.driversGroups?.selected;
    }

    if (selectedTab == 'idling') {
        if (groupBy == 'vehicle') {
            query += '/vehicles/fleet-idling';
            dataFilter['vehicleGroups'] = filter?.vehiclesGroups?.selected;
            dataFilter['vehicles'] = filter?.vehicles?.selected;
            dataFilter['make'] = filter?.make?.selected;
            dataFilter['models'] = filter?.model?.selected;
            dataFilter['years'] = filter?.year;
        } else if (groupBy == 'driver') {
            query += '/drivers/fleet-idling';
            dataFilter['drivers'] = filter?.drivers?.selected;
            dataFilter['driverGroups'] = filter?.driversGroups?.selected;
        } else {
            query += '/default/fleet-idling';
            dataFilter['vehicleGroups'] = filter?.vehiclesGroups?.selected;
            dataFilter['vehicles'] = filter?.vehicles?.selected;
            dataFilter['make'] = filter?.make?.selected;
            dataFilter['models'] = filter?.model?.selected;
            dataFilter['years'] = filter?.year;
            dataFilter['drivers'] = filter?.drivers?.selected;
            dataFilter['driverGroups'] = filter?.driversGroups?.selected;
        }
    }
    if (selectedTab == 'overdue_maintenance') {
        query += '/overdue-maintenance';
        dataFilter['vehicleGroups'] = filter?.vehiclesGroups?.selected;
        dataFilter['vehicles'] = filter?.vehicles?.selected;
        dataFilter['make'] = filter?.make?.selected;
        dataFilter['models'] = filter?.model?.selected;
        dataFilter['years'] = filter?.year;
    }

    if (selectedTab == 'dtc') {
        query += '/dtc';
        dataFilter['categories'] = filter?.dtcType?.selected;
        dataFilter['vehicleGroups'] = filter?.vehiclesGroups?.selected;
        dataFilter['vehicles'] = filter?.vehicles?.selected;
        dataFilter['make'] = filter?.make?.selected;
        dataFilter['models'] = filter?.model?.selected;
        dataFilter['years'] = filter?.year;
        dataFilter['drivers'] = filter?.drivers?.selected;
        dataFilter['driverGroups'] = filter?.driversGroups?.selected;
    }

    if (selectedTab == 'trips') {
        if (groupBy == 'vehicle') {
            query += '/vehicles/trip';
            dataFilter['vehicleGroups'] = filter?.vehiclesGroups?.selected;
            dataFilter['vehicles'] = filter?.vehicles?.selected;
            dataFilter['make'] = filter?.make?.selected;
            dataFilter['models'] = filter?.model?.selected;
            dataFilter['years'] = filter?.year;
            dataFilter['badBehavireDriver'] = filter?.badBehavireDriver;
        } else if (groupBy == 'driver') {
            query += '/drivers/trip';
            dataFilter['drivers'] = filter?.drivers?.selected;
            dataFilter['driverGroups'] = filter?.driversGroups?.selected;
            dataFilter['badBehavireDriver'] = filter?.badBehavireDriver;
        } else {
            query += '/default/trip';
            dataFilter['vehicleGroups'] = filter?.vehiclesGroups?.selected;
            dataFilter['vehicles'] = filter?.vehicles?.selected;
            dataFilter['make'] = filter?.make?.selected;
            dataFilter['models'] = filter?.model?.selected;
            dataFilter['years'] = filter?.year;
            dataFilter['drivers'] = filter?.drivers?.selected;
            dataFilter['driverGroups'] = filter?.driversGroups?.selected;
            dataFilter['badBehavireDriver'] = filter?.badBehavireDriver;
        }
    }

    return { dataFilter, query };
};

const getFileHeadersAndRows = (selectedTab: string, groupBy: string, response: any) => {
    const vehicleHeaders = ['Plate', 'Make', 'Model', 'Year', 'Vehicle Group'];
    const driverHeaders = ['Driver Name', 'Driver Group'];
    const getBaseInfo = (item, group = groupBy) => {
        return group == 'vehicle'
            ? [
                item?.vehicle?.plate,
                item?.vehicle?.make,
                item?.vehicle?.model,
                item?.vehicle?.year,
                item?.vehicleGroup?.groupName || item?.vehicle?.group,
            ]
            : group == 'driver'
                ? [item?.driver?.name, item?.driverGroup?.groupName || item?.driver?.group]
                : [
                    item?.vehicle?.plate,
                    item?.vehicle?.make,
                    item?.vehicle?.model,
                    item?.vehicle?.year,
                    item?.vehicleGroup?.groupName,
                    item?.driver?.name,
                    item?.driverGroup?.groupName,
                ];
    };
    const getBaseHeaders = () => {
        return groupBy == 'vehicle'
            ? vehicleHeaders
            : groupBy == 'driver'
                ? driverHeaders
                : [...vehicleHeaders, ...driverHeaders];
    };
    let headers: string[] = [];
    let data: any[] = [];

    switch (selectedTab) {
        case 'speeding':
            headers = groupBy
                ? [
                    ...getBaseHeaders(),
                    '5-10km/h',
                    '11-20km/h',
                    '21-30km/h',
                    '31-40km/h',
                    '41-50km/h',
                    '>50km/h',
                ]
                : [...getBaseHeaders(), 'Time', 'Location', 'Max Speed', 'Duration'];
            data = response?.map((item: any) => {
                const baseInfo = getBaseInfo(item);

                const speedingInfo = groupBy
                    ? [
                        `Count: ${item[`overspeeding_5_10_count`]}, Avg Duration: ${parseFloat(
                            item[`avg_duration_5_10`]
                        ).toFixed(1)}min`,
                        `Count: ${item[`overspeeding_11_20_count`]}, Avg Duration: ${parseFloat(
                            item[`avg_duration_11_20`]
                        ).toFixed(1)}min`,
                        `Count: ${item[`overspeeding_21_30_count`]}, Avg Duration: ${parseFloat(
                            item[`avg_duration_21_30`]
                        ).toFixed(1)}min`,
                        `Count: ${item[`overspeeding_31_40_count`]}, Avg Duration: ${parseFloat(
                            item[`avg_duration_31_40`]
                        ).toFixed(1)}min`,
                        `Count: ${item[`overspeeding_41_50_count`]}, Avg Duration: ${parseFloat(
                            item[`avg_duration_41_50`]
                        ).toFixed(1)}min`,
                        `Count: ${item[`overspeeding_gt_50_count`]}, Avg Duration: ${parseFloat(
                            item[`avg_duration_gt_50`]
                        ).toFixed(1)}min`,
                    ]
                    : [
                        `${EnglishConvertServices.convertDatesToTimezone(item?.start)}`,
                        item?.address?.replace('undefined', '-') || '-',
                        item?.maxSpeedReached,
                        item?.duration
                            ? new Date(item?.duration * 1000).toISOString().substring(11, 19)
                            : '00:00:00',
                    ];

                return [...baseInfo, ...speedingInfo];
            });
            break;

        case 'idling':
            headers = groupBy
                ? [
                    ...getBaseHeaders(),
                    '10-20min',
                    '21-40min',
                    '41-60min',
                    '>60min',
                    'Total Idling',
                    'Idling Percentage Of Trips Duration',
                ]
                : [...getBaseHeaders(), 'Idling Time'];
            data = response?.map((item: any) => {
                const baseInfo = getBaseInfo(item);

                const idlingInfo = groupBy
                    ? [
                        item?.trips_10_20_count,
                        item?.trips_21_40_count,
                        item?.trips_41_60_count,
                        item?.trips_gt_60_count,
                        `${parseFloat(item?.total_idling).toFixed(1)}min`,
                        `${parseFloat(item?.idling_to_duration).toFixed(1)}%`,
                    ]
                    : [`${parseFloat(item?.idle_time).toFixed(1)}min`];

                return [...baseInfo, ...idlingInfo];
            });
            break;

        case 'harsh_events':
            headers = groupBy
                ? [...getBaseHeaders(), 'Cornering', 'Acceleration', 'Braking']
                : [...getBaseHeaders(), 'Type', 'Time', 'Location'];
            data = response?.map((item: any) => {
                const baseInfo = getBaseInfo(item);

                const eventsInfo = groupBy
                    ? [item?.cornering, item?.acceleration, item?.braking]
                    : [
                        item?.type,
                        `${EnglishConvertServices.convertDatesToTimezone(item?.start)}`,
                        item?.address?.replace('undefined', '-') || '-',
                    ];

                return [...baseInfo, ...eventsInfo];
            });

            break;

        case 'dtc':
            headers = [...getBaseHeaders(), 'Time', 'DTC', 'Severity', 'Causes', 'Category'];

            data = response?.map((item: any) => [
                item?.vehicle?.plate,
                item?.vehicle?.make,
                item?.vehicle?.model,
                item?.vehicle?.year,
                item?.vehicleGroup?.groupName,
                item?.driver?.name,
                item?.driverGroup?.groupName,
                `${EnglishConvertServices.convertDatesToTimezone(item?.start)}`,
                item?.code,
                item?.severity,
                item?.cause,
                item?.category,
            ]);
            break;

        case 'geofences':
            headers = [...getBaseHeaders(), 'Time', 'Geofence Name', 'Trigger', 'Duration'];
            data = response?.map((item: any) => [
                item?.vehicle?.plate,
                item?.vehicle?.make,
                item?.vehicle?.model,
                item?.vehicle?.year,
                item?.vehicleGroup?.groupName,
                item?.driver?.name,
                item?.driverGroup?.groupName,
                `${EnglishConvertServices.convertDatesToTimezone(item?.start)}`,
                item?.geofence_info?.info?.name,
                userService.getTriggerName(item?.geofence_info),
                item?.duration
                    ? new Date(item?.duration * 1000).toISOString().substring(11, 19)
                    : '00:00:00',
            ]);
            break;

        case 'mileage':
            headers = [...getBaseHeaders(), 'Mileage'];
            data = response?.map((item: any) => {
                const baseInfo = getBaseInfo(item);
                const mileageInfo = [`${parseFloat(item?.total_distance || 0).toFixed(0)}km`];
                return [...baseInfo, ...mileageInfo];
            });
            break;

        case 'overdue_maintenance':
            headers = [...vehicleHeaders, 'Expected Cost'];
            data = response?.map((item: any) => {
                const baseInfo = getBaseInfo(item, 'vehicle');
                const overdueInfo = [
                    `${parseFloat(
                        item?.notifications?.[0]?.items?.reduce(
                            (acc, currentValue) => acc + currentValue?.cost,
                            0
                        ) || 0
                    ).toFixed(0)}`,
                ];
                return [...baseInfo, ...overdueInfo];
            });
            break;
        case 'trips':
            headers = groupBy
                ? [
                    ...getBaseHeaders(),
                    'Duration',
                    'Idling',
                    'Driven Distance',
                    'Vehicle Score',
                    'Driver Score',
                ]
                : [
                    'Start',
                    'End ',
                    ...getBaseHeaders(),
                    'Start Location',
                    'End Location',
                    'Duration',
                    'Idling',
                    'Driven Distance',
                    'Vehicle Score',
                    'Driver Score',
                ];
            data = response?.map((item: any) => {
                const baseInfo = getBaseInfo(item);
                const overdueInfo = groupBy
                    ? [
                        item?.duration
                            ? new Date(item?.duration * 1000).toISOString().substring(11, 19)
                            : '00:00:00',
                        `${parseFloat(item?.idle_time).toFixed(0)} min`,
                        `${item?.distance?.toFixed(1)} km`,
                        `${parseInt(item?.vehicle_score)}%`,
                        `${parseInt(item?.driver_score)}%`,
                    ]
                    : [
                        item?.start_address?.replace('undefined', ''),
                        item?.end_address?.replace('undefined', ''),
                        item?.duration
                            ? new Date(item?.duration * 1000).toISOString().substring(11, 19)
                            : '00:00:00',
                        `${parseFloat(item?.idle_time).toFixed(0)} min`,
                        `${item?.distance?.toFixed(1)} km`,
                        `${parseInt(item?.vehicle_score)}%`,
                        `${parseInt(item?.driver_score)}%`,
                    ];
                const time = groupBy
                    ? []
                    : [
                        EnglishConvertServices.convertDatesToTimezone(item?.start) || '-',
                        EnglishConvertServices.convertDatesToTimezone(item?.end) || '-',
                    ];
                return [...time, ...baseInfo, ...overdueInfo];
            });
            break;
        default:
            break;
    }

    return { headers, rows: data };
};

export const getVehiclesGroups =
    (companyId: string, page: number, errorMessage: string) => async (dispatch) => {
        try {
            const { data } = await axiosApiInstance.get(
                `${API_URL}/vehicle-groups/${companyId}?page=${page}&limit=10`
            );
            // data.unshift({ _id: "Default", group_name: "Default" });
            data && dispatch(setVehiclesGroups(data));
        } catch (error) {
            Notification(errorMessage, 'error');
        }
    };

export const getReportVehiclesList =
    (companyId: string, page: number, errorMessage: string) => async (dispatch, getState) => {
        try {
            const reportVehiclesSearchValue = getState()?.reports?.reportVehiclesSearchValue;
            let query = `${API_URL}/companies/${companyId}/vehicles/search?term=${reportVehiclesSearchValue || '*'
                }&page=${page}&perPage=10`;
            const { data } = await axiosApiInstance.get(query);
            data && dispatch(setReportVehiclesList(data));
        } catch (error) {
            Notification(errorMessage, 'error');
        }
    };

export const getReportDriversList =
    (companyId: string, page: number, errorMessage: string) => async (dispatch, getState) => {
        try {
            const reportDriversSearchValue = getState()?.reports?.reportDriversSearchValue;
            let query = `${API_URL}/companies/${companyId}/drivers/search?term=${reportDriversSearchValue || '*'
                }&page=${page}&perPage=10`;
            const { data } = await axiosApiInstance.get(query);
            data && dispatch(setReportDriversList(data));
        } catch (error) {
            Notification(errorMessage, 'error');
        }
    };
export const getDriversGroups =
    (companyId: string, page: number, errorMessage: string) => async (dispatch) => {
        try {
            const { data } = await axiosApiInstance.get(
                `${API_URL}/driver-groups/${companyId}?page=${page}&limit=10`
            );
            data && dispatch(setDriversGroups(data));
        } catch (error) {
            Notification(errorMessage, 'error');
        }
    };

export const initialReportFilter =
    (companyId: string, page: number, limit: number, errorMessage: string) => async (dispatch) => {
        try {
            const { data } = await axiosApiInstance.get(
                `${API_URL}/vehicles/${companyId}/vehicles-filter`
            );
            dispatch(initialReportFilterYear(data[0]));
        } catch (err) { }
        dispatch(getReportData(companyId, page, limit, errorMessage));
    };
export const getReportData =
    (companyId: string, page: number, limit: number, errorMessage: string) =>
        async (dispatch, getState) => {
            const MAX_RETRIES = 5;
            const RETRY_DELAY = 3000; // 3 seconds in milliseconds
            let retries = 0;
            dispatch(
                setFilterData({
                    loading: true,
                })
            );
            while (retries < MAX_RETRIES) {
                try {
                    const filter = getState()?.reports?.filter;
                    const selectedTab = getState()?.reports?.selectedTab;
                    const groupBy = getState()?.reports?.groupBy;
                    const { query, dataFilter } = getDataFilterAndQuery(selectedTab, filter, groupBy);

                    const response = await axiosApiInstance.get(
                        `${API_URL}/report/${companyId}${query}`,
                        {
                            params: { ...dataFilter, page, limit },
                        }
                    );

                    const { data } = response;
                    dispatch(
                        setFilterData({
                            data: data?.data,
                            pagination: {
                                totalPages: Math.ceil(data?.metadata?.totalDocs / limit),
                                page,
                                currentResults: data?.data?.length,
                                totalResults: data?.metadata?.totalDocs,
                                resultsPerPage: limit,
                            },
                            loading: false,
                        })
                    );
                    break; // Break the loop if the request succeeds
                } catch (error) {
                    if (error?.response?.status === 500) {
                        retries++;
                        if (retries === MAX_RETRIES) {
                            dispatch(
                                setFilterData({
                                    loading: false,
                                })
                            );
                            Notification(errorMessage, 'error');
                        }
                        await new Promise((resolve) => setTimeout(resolve, RETRY_DELAY));
                    } else {
                        dispatch(
                            setFilterData({
                                loading: false,
                            })
                        );
                        Notification(errorMessage, 'error');
                        break; // Break the loop if the error status code is not 500
                    }
                }
            }
        };
export const generateReport = (companyId: string) => async (dispatch, getState) => {
    try {
        dispatch(
            setFilterData({
                loading: true,
            })
        );
        const filter = getState()?.reports?.filter;
        const selectedTab = getState()?.reports?.selectedTab;
        const groupBy = getState()?.reports?.groupBy;
        const { query, dataFilter } = getDataFilterAndQuery(selectedTab, filter, groupBy);

        // Make an API request to fetch the data
        const { data } = await axiosApiInstance.get(`${API_URL}/report/${companyId}${query}`, {
            params: { ...dataFilter, page: 1, limit: 1000000000 },
        });

        const { headers, rows } = getFileHeadersAndRows(selectedTab, groupBy, data?.data);
        const customHeaders = headers;
        const csvData = rows;
        csvData.unshift(customHeaders);

        const csv = Papa.unparse(csvData);
        const blob = new Blob([new Uint8Array([0xef, 0xbb, 0xbf]), csv], {
            type: 'text/csv;charset=utf-8',
        });

        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');

        a.href = url;
        a.download =
            selectedTab == 'mileage'
                ? 'Mileage'
                : selectedTab == 'idling'
                    ? 'Idling'
                    : selectedTab == 'accidents'
                        ? 'Accidents'
                        : selectedTab == 'harsh_events'
                            ? 'Harsh Events'
                            : selectedTab == 'dtc'
                                ? 'DTC'
                                : selectedTab == 'trips'
                                    ? 'Trips'
                                    : selectedTab == 'overdue_maintenance'
                                        ? 'Overdue Maintenance'
                                        : selectedTab == 'speeding'
                                            ? 'Overspeed'
                                            : selectedTab == 'geofences' && 'GeoFence';
        a.download = a.download + (groupBy ? `_(${groupBy})_` : '_') + dayjs().format('YYYY:DD:MM');

        document.body.appendChild(a);

        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
        dispatch(
            setFilterData({
                loading: false,
            })
        );
    } catch (error) {
        dispatch(
            setFilterData({
                loading: false,
            })
        );
    }
};

const { actions, reducer } = ReportsSlice;
export const {
    setSelectedTab,
    setFilterPeriod,
    setVehiclesGroups,
    setReportVehiclesList,
    setReportDriversList,
    setDriversGroups,
    setSelectedGroups,
    setReportFilterField,
    resetField,
    resetBooleanField,
    resetFilter,
    setFilterData,
    setReportVehiclesSearchValue,
    setReportDriversSearchValue,
    initialReportFilterYear,
    setSelectedGroupBy,
    setProblemsAffecting,
} = actions;
export default reducer;
