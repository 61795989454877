import _get from "lodash/get";
import Cookies from "universal-cookie";
import Router from "next/router";
import cookie from "js-cookie";
var AUTH_COOKIE_KEY = process.env.NEXT_PUBLIC_AUTH;
export function getTokenFromContext(ctx) {
  var contextCookies = _get(ctx, "req.headers.cookie");
  var cookies = new Cookies(contextCookies);
  return cookies.get(AUTH_COOKIE_KEY);
}
export function getEmailFromContext(ctx) {
  var contextCookies = _get(ctx, "req.headers.cookie");
  var cookies = new Cookies(contextCookies);
  return cookies.get("CompanyName");
}
export function handleAuthTokenClear() {
  if (typeof window !== "undefined") {
    cookie.remove(AUTH_COOKIE_KEY, {
      expires: 1
    });
    cookie.remove("CompanyName", {
      expires: 1
    });
  }
  Router.replace("/");
}