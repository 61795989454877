import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { enableMapSet } from 'immer';
enableMapSet();
import vehiclesReducer from '../../../../pages/vehicles/vehiclesRedux/VehiclesIndex';
import vehiclesServiceHistoryReducer from '../../../../pages/service/vehiclesServiceHistoryRedux/VehiclesServiceHistoryIndex';
import recommendedToSellReducer from '../../../../pages/recommended-to-sell/recommendedToSellRedux/RecommendedToSellIndex';
import pendingDriversReducer from '../../../../pages/pending-approval-users/pending-approval-users-redux/pending-approval-users-index';
import dashboardReducer from '../../../../pages/dashboard/mainPageRedux/MainPageIndex';
import driversReducer from '../../../../pages/drivers/driversRedux/DriversIndex';
import singleVehicleReducer from '../../../../pages/vehicle-dashboard/singleVehicleRedux/SingleVehicleIndex';
import singleDriverTrducer from '../../../../pages/driver-dashboard/singleDriverRedux/SingleDriverIndex';
import singleTripReducer from '../../../../pages/trip-details/tripDetailsRedux/TripDetailsIndex';
import vehicleInformationReducer from '../../../../pages/vehicle-information/vehicleInformationRedux/VehicleInformationIndex';
import driverInformationReducer from '../../../../pages/driver-information/driverInformationRedux/DriverInformationIndex';
import driversAssigningReducer from '../../../../pages/driversAssigning/driversAssigningRedux/DriversAssigningIndex';
import vehiclesAssigningReducer from '../../../../pages/vehiclesAssigning/vehiclesAssigningRedux/VehiclesAssigningIndex';
import settingsReducer from '../../../../pages/settings/settingsRedux/SettingsIndex';
import ServiceHistoryReducer from '../../../../pages/service-history/serviceHistoryRedux/ServiceHistoryIndex';
import BookedVehiclesReducer from '../../../../pages/booked-vehicles/bookedVehiclesRedux/BookedVehiclesIndex';
import SearchReducer from '../../../../pages/search/searchRedux/SearchIndex';
import ReportsReducer from '../../../../pages/reports/reportsRedux/ReportsIndex';
// Middleware to load state from localStorage
// Middleware to load state from localStorage
// const loadState = () => {
//     try {
//         if (typeof localStorage !== 'undefined') {
//             const serializedState = localStorage.getItem('reduxState');
//             if (serializedState === null) {
//                 return undefined;
//             }
//             return JSON.parse(serializedState);
//         } else {
//             console.warn('localStorage is not available in this environment.');
//             return undefined;
//         }
//     } catch (err) {
//         console.error('Error loading state from localStorage:', err);
//         return undefined; // Return undefined if there is an error
//     }
// };

var store = configureStore({
  reducer: combineReducers({
    vehicles: vehiclesReducer,
    vehiclesServiceHistory: vehiclesServiceHistoryReducer,
    recommendedToSell: recommendedToSellReducer,
    dashboard: dashboardReducer,
    drivers: driversReducer,
    singleVehicle: singleVehicleReducer,
    vehicleInfo: vehicleInformationReducer,
    driverInfo: driverInformationReducer,
    singleDriver: singleDriverTrducer,
    singleTrip: singleTripReducer,
    driversAssigning: driversAssigningReducer,
    vehiclesAssigning: vehiclesAssigningReducer,
    settings: settingsReducer,
    pendingDrivers: pendingDriversReducer,
    serviceHistory: ServiceHistoryReducer,
    bookedVehicles: BookedVehiclesReducer,
    search: SearchReducer,
    reports: ReportsReducer
  })
  // preloadedState: loadedState, // Set the preloaded state
});

// Middleware to save state to localStorage
store.subscribe(function () {
  if (window) {
    // Create a URL object from the current window location
    var _url = new URL(window.location.href);
    if (window.location.pathname.includes('reports')) {
      var _state = store.getState();
      var _obj = JSON.parse(JSON.stringify(_state.reports));
      _obj.filter.vehicles.data = [];
      _obj.filter.drivers.data = [];
      _obj.filter.vehiclesGroups.data = [];
      _obj.filter.driversGroups.data = [];
      delete _obj.filterData;
      // Set or update a search parameter
      _url.searchParams.set('state', JSON.stringify(_obj));
      // Replace the current URL with the updated URL
      window.history.replaceState({}, '', _url.href);
    }
  }
});
export default store;