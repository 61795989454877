import _toConsumableArray from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
import { enableMapSet } from 'immer';
enableMapSet();
import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import rootReducer from "../REDUX/Index";
import geofenceReducer from '../geofenceRedux/GeofenceIndex';
var store = configureStore({
  reducer: {
    maps: rootReducer,
    geofence: geofenceReducer
  },
  middleware: _toConsumableArray(getDefaultMiddleware({
    serializableCheck: false
  }))
});
export default store;