import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
import * as timezone from 'dayjs/plugin/timezone';
import * as customParseFormat from 'dayjs/plugin/customParseFormat';
import { userService } from '../../../services';
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);
export var ArabicConvertServices = {
  convertToHHMM: convertToHHMM,
  getTotalTime: getTotalTime,
  getTotalTimeByNumber: getTotalTimeByNumber,
  calculateTotalServiceTime: calculateTotalServiceTime,
  getTotalCost: getTotalCost,
  isDate: isDate,
  formatDate: formatDate,
  TimeDifferenceByD: TimeDifferenceByD,
  TimeDifferenceByYMD: TimeDifferenceByYMD,
  secondsToYMDHM: secondsToYMDHM,
  TimeDifferenceByMDH: TimeDifferenceByMDH,
  YearsToYearsMonthsDays: YearsToYearsMonthsDays,
  numberWithCommas: numberWithCommas,
  getVehicleAge: getVehicleAge,
  ConvertHours: ConvertHours,
  decimalToDaysHoursMinutes: decimalToDaysHoursMinutes,
  convertMinutesToHoursMinutes: convertMinutesToHoursMinutes,
  removeColon: removeColon,
  getHM: getHM,
  TimeDifferenceByM: TimeDifferenceByM,
  calculatePercentage: calculatePercentage,
  convertDaysToYYMMDD: convertDaysToYYMMDD,
  dayjsDiffByYMD: dayjsDiffByYMD,
  dayjsDiffByD: dayjsDiffByD,
  diffInYMDHM: diffInYMDHM,
  convertDatesToTimezone: convertDatesToTimezone,
  capitalizeText: capitalizeText,
  convertToStringDate: convertToStringDate,
  convertMapsEventDateFormat: convertMapsEventDateFormat,
  reverseSectionsSeparatedByColon: reverseSectionsSeparatedByColon,
  hexToColorName: hexToColorName,
  getRemainingTime: getRemainingTime,
  getDuration: getDuration,
  minutesToYMDHM: minutesToYMDHM,
  isVehicleActive: isVehicleActive
};
function isVehicleActive(timestamp) {
  var date1 = dayjs(timestamp);
  var date2 = dayjs();
  var diff = date2.diff(date1, 'm');
  return Math.abs(diff) <= 2;
}
function getDuration(date_1, date_2) {
  var companyTimezone = userService.getCompanyTimezone();
  // Create two dayjs dates
  var date1 = dayjs(date_1).tz(companyTimezone);
  var date2 = dayjs(date_2).tz(companyTimezone);

  // Calculate the difference in milliseconds
  var diffMilliseconds = date2.diff(date1);

  // Calculate hours, remaining minutes, and remaining seconds
  var hours = Math.floor(diffMilliseconds / (1000 * 60 * 60));
  var remainingMilliseconds = diffMilliseconds - hours * 1000 * 60 * 60;
  var minutes = Math.floor(remainingMilliseconds / (1000 * 60));
  var remainingSeconds = (remainingMilliseconds - minutes * 1000 * 60) / 1000;
  return {
    h: hours,
    m: minutes,
    s: remainingSeconds
  };
}
//needs time converter
function minutesToYMDHM(seconds) {
  seconds = Number(seconds);
  var d = Math.floor(seconds / (3600 * 24));
  var h = Math.floor(seconds % (3600 * 24) / 3600);
  var m = Math.floor(seconds % 3600 / 60);
  var dDisplay = d > 0 ? d + 'ي:' : '';
  var hDisplay = h > 0 ? h + 'س:' : '';
  var mDisplay = m > 0 ? m + 'د' : '';
  return (dDisplay + hDisplay + mDisplay).replace(/:$/, '');
}
function getRemainingTime(end, start) {
  var end_time = new Date(end);
  var start_Time = new Date(start);
  var msInMinute = 60 * 1000;
  var remain = Math.round((end_time - start_Time) / msInMinute);
  return remain > 0 ? minutesToYMDHM(remain * 60) : '0' + 'د';
}
function hexToColorName(hexCode) {
  var _hexCode, _hexCode2, _hexCode3, _hexCode4;
  // Remove the '#' symbol if it's present
  hexCode = (_hexCode = hexCode) === null || _hexCode === void 0 ? void 0 : _hexCode.replace('#', '');

  // Convert the hex code to RGB values
  var r = parseInt((_hexCode2 = hexCode) === null || _hexCode2 === void 0 ? void 0 : _hexCode2.substring(0, 2), 16);
  var g = parseInt((_hexCode3 = hexCode) === null || _hexCode3 === void 0 ? void 0 : _hexCode3.substring(2, 4), 16);
  var b = parseInt((_hexCode4 = hexCode) === null || _hexCode4 === void 0 ? void 0 : _hexCode4.substring(4, 6), 16);

  // Define a list of color names mapped to their corresponding RGB values
  var colorNames = {
    '000000': 'أسود',
    FFFFFF: 'أبيض',
    FF0000: 'أحمر',
    '00FF00': 'أخضر',
    '0000FF': 'أزرق',
    FFFF00: 'أصفر',
    FFC0CB: 'زهري'
    // Add more color mappings as needed
  };

  // Find the closest color match
  var closestColor = '';
  var minColorDistance = Number.MAX_VALUE;
  for (var _color2 in colorNames) {
    var _rDiff = r - parseInt(_color2 === null || _color2 === void 0 ? void 0 : _color2.substring(0, 2), 16);
    var _gDiff = g - parseInt(_color2 === null || _color2 === void 0 ? void 0 : _color2.substring(2, 4), 16);
    var _bDiff = b - parseInt(_color2 === null || _color2 === void 0 ? void 0 : _color2.substring(4, 6), 16);
    var _colorDistance = _rDiff * _rDiff + _gDiff * _gDiff + _bDiff * _bDiff;
    if (_colorDistance < minColorDistance) {
      minColorDistance = _colorDistance;
      closestColor = colorNames === null || colorNames === void 0 ? void 0 : colorNames[_color2];
    }
  }
  return closestColor;
}
function reverseSectionsSeparatedByColon(inputString) {
  var sections = inputString.split(':');
  var reversedSections = sections.map(function (section) {
    // Check if the section is a number before reversing
    if (/^[0-9]+$/.test(section)) {
      return section.split('').reverse().join('');
    } else {
      return section;
    }
  });
  return reversedSections.join(':');
}
function capitalizeText(model) {
  var _model$toLowerCase;
  // Split the model into an array of words
  var words = model === null || model === void 0 ? void 0 : (_model$toLowerCase = model.toLowerCase()) === null || _model$toLowerCase === void 0 ? void 0 : _model$toLowerCase.split(' ');

  // Capitalize the first letter of each word
  var capitalizedWords = words === null || words === void 0 ? void 0 : words.map(function (word) {
    var _word$charAt;
    return (word === null || word === void 0 ? void 0 : (_word$charAt = word.charAt(0)) === null || _word$charAt === void 0 ? void 0 : _word$charAt.toUpperCase()) + (word === null || word === void 0 ? void 0 : word.slice(1));
  });

  // Join the capitalized words back into a sentence
  var capitalizedSentence = capitalizedWords === null || capitalizedWords === void 0 ? void 0 : capitalizedWords.join(' ');
  return model == 'ch-r' ? 'CH-R' : model == 'c-hr' ? 'C-HR' : model == 'gmc' ? 'GMC' : capitalizedSentence;
}
var days = {
  Sat: 'السبت',
  Sun: 'الأحد',
  Mon: 'الإثنين',
  Tue: 'الثلاثاء',
  Wed: 'الأربعاء',
  Thu: 'الخميس',
  Fri: 'الجمعة'
};
var regex = new RegExp(Object.keys(days).join('|'), 'g');
function convertDatesToTimezone(date) {
  var format = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'MM/DD/YYYY hh:mm:ss A';
  return dayjs(date).tz(userService.getCompanyTimezone()).format(format).replace('AM', 'ص').replace('am', 'ص').replace('PM', 'م').replace('pm', 'م').replace(regex, function (match) {
    return days[match];
  });
}
function dayjsDiffByYMD(date) {
  // Set the current date
  var currentDate = dayjs();

  // Set the second date
  var secondDate = dayjs(date, 'DD-MM-YYYY');

  // Calculate the difference in years, months, and days
  var diffYears = secondDate.diff(currentDate, 'year');
  var diffMonths = secondDate.diff(currentDate.add(diffYears, 'year'), 'month');
  var diffDays = secondDate.diff(currentDate.add(diffYears, 'year').add(diffMonths, 'month'), 'day');

  // Create an array to store the difference components
  var differenceArray = [];

  // Check if each component is greater than 0 and add it to the array
  if (diffYears > 0) {
    differenceArray.push(diffYears + (diffYears > 2 && diffYears <= 10 ? 'سنين ' : 'سنة '));
  }
  if (diffMonths > 0) {
    differenceArray.push(diffMonths + (diffMonths > 2 && diffMonths <= 10 ? 'شهور ' : 'شهر '));
  }
  if (diffDays > 0) {
    differenceArray.push(diffDays + (diffDays > 2 && diffDays <= 10 ? 'أيام ' : 'يوم '));
  }

  // Join the difference components into a string
  var difference = differenceArray.join(' ');
  return difference;
}
function dayjsDiffByD(date) {
  // Set the current date
  var currentDate = dayjs();

  // Set the second date
  var secondDate = dayjs(date, 'DD-MM-YYYY');

  // Calculate the difference in days
  var diffInDays = secondDate.diff(currentDate, 'day');
  return diffInDays;
}
function diffInYMDHM(timestamp) {
  var currentDate = new Date();
  var diff = Math.abs(currentDate - timestamp);
  var millisecondsPerMinute = 60 * 1000;
  var millisecondsPerHour = 60 * millisecondsPerMinute;
  var millisecondsPerDay = 24 * millisecondsPerHour;
  var millisecondsPerMonth = 30 * millisecondsPerDay;
  var millisecondsPerYear = 365 * millisecondsPerDay;
  var years = Math.floor(diff / millisecondsPerYear);
  var months = Math.floor(diff % millisecondsPerYear / millisecondsPerMonth);
  var days = Math.floor(diff % millisecondsPerMonth / millisecondsPerDay);
  var hours = Math.floor(diff % millisecondsPerDay / millisecondsPerHour);
  var minutes = Math.floor(diff % millisecondsPerHour / millisecondsPerMinute);
  var result = '';
  if (years > 0) {
    result += years + (years > 2 && years <= 10 ? 'سنين ' : 'سنة ');
  }
  if (months > 0) {
    result += months + (months > 2 && months <= 10 ? 'شهور ' : 'شهر ');
  }
  if (days > 0) {
    result += days + (days > 2 && days <= 10 ? 'أيام ' : 'يوم ');
  }
  if (hours > 0) {
    result += hours + (hours > 2 && hours <= 10 ? 'ساعات ' : 'ساعة ');
  }
  if (minutes > 0) {
    result += minutes + (minutes > 2 && minutes <= 10 ? 'دقائق ' : 'دقيقة ');
  }
  return result;
}
function calculatePercentage(oldValue, newValue) {
  var oldV = Number(oldValue);
  var newV = Number(newValue);
  if (oldV == 0) {
    return 0;
  }
  var percentageChange = (newV - oldV) / oldV * 100;
  return parseFloat(percentageChange).toFixed(2);
}
function convertMinutesToHoursMinutes(minutes) {
  var hours = Math.floor(minutes / 60);
  var remainingMinutes = minutes % 60;
  return {
    hours: hours,
    remainingMinutes: remainingMinutes
  };
}
function removeColon(data) {
  var _str, _str3;
  var str = data === null || data === void 0 ? void 0 : data.trim();
  if ((_str = str) !== null && _str !== void 0 && _str.startsWith(':')) {
    var _str2;
    str = (_str2 = str) === null || _str2 === void 0 ? void 0 : _str2.substring(1);
  }
  if ((_str3 = str) !== null && _str3 !== void 0 && _str3.endsWith(':')) {
    var _str4, _str5;
    str = (_str4 = str) === null || _str4 === void 0 ? void 0 : _str4.substring(0, ((_str5 = str) === null || _str5 === void 0 ? void 0 : _str5.length) - 1);
  }
  return str;
}
function convertToHHMM() {
  var min = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
  var minutes = min % 60;
  minutes < 10 && (minutes = '0' + minutes);
  var hours = (min - minutes) / 60;
  hours < 10 && (hours = '0' + hours);
  return hours + ':' + minutes;
}
function isDate(str) {
  return new Date(str) !== 'Invalid Date' && !isNaN(new Date(str));
}
function getTotalTime(items) {
  if (!items) return;
  var hours = items === null || items === void 0 ? void 0 : items.reduce(function (accumulator, object) {
    var _object$service_time, _object$service_time2;
    return !(object !== null && object !== void 0 && (_object$service_time = object.service_time) !== null && _object$service_time !== void 0 && _object$service_time.includes('_')) ? accumulator + Number((object === null || object === void 0 ? void 0 : (_object$service_time2 = object.service_time) === null || _object$service_time2 === void 0 ? void 0 : _object$service_time2.split(':')[0]) || 0) : 0;
  }, 0);
  var minutes = items === null || items === void 0 ? void 0 : items.reduce(function (accumulator, object) {
    var _object$service_time3, _object$service_time4;
    return !(object !== null && object !== void 0 && (_object$service_time3 = object.service_time) !== null && _object$service_time3 !== void 0 && _object$service_time3.includes('_')) ? accumulator + Number((object === null || object === void 0 ? void 0 : (_object$service_time4 = object.service_time) === null || _object$service_time4 === void 0 ? void 0 : _object$service_time4.split(':')[1]) || 0) : 0;
  }, 0);
  hours += Math.floor(minutes / 60);
  minutes = minutes % 60;
  return (hours > 0 ? hours + (hours > 2 && hours <= 10) ? 'ساعات' : 'ساعة' : '') + (minutes > 0 ? minutes + (minutes > 2 && minutes <= 10) ? 'دقائق' : 'دقيقة' : '');
}
function calculateTotalServiceTime(items) {
  return items === null || items === void 0 ? void 0 : items.reduce(function (accumulator, object) {
    return accumulator + Number((object === null || object === void 0 ? void 0 : object.service_time) || 0);
  }, 0);
}
function getTotalTimeByNumber(items) {
  var hours = items === null || items === void 0 ? void 0 : items.reduce(function (accumulator, object) {
    var _object$service_time5, _object$service_time6, _object$service_time7;
    return !(object !== null && object !== void 0 && (_object$service_time5 = object.service_time) !== null && _object$service_time5 !== void 0 && (_object$service_time6 = _object$service_time5.toString()) !== null && _object$service_time6 !== void 0 && _object$service_time6.includes('_')) ? accumulator + Number((object === null || object === void 0 ? void 0 : (_object$service_time7 = object.service_time) === null || _object$service_time7 === void 0 ? void 0 : _object$service_time7.split(':')[0]) || 0) : 0;
  }, 0);
  var minutes = items === null || items === void 0 ? void 0 : items.reduce(function (accumulator, object) {
    var _object$service_time8, _object$service_time9, _object$service_time10;
    return !(object !== null && object !== void 0 && (_object$service_time8 = object.service_time) !== null && _object$service_time8 !== void 0 && (_object$service_time9 = _object$service_time8.toString()) !== null && _object$service_time9 !== void 0 && _object$service_time9.includes('_')) ? accumulator + Number((object === null || object === void 0 ? void 0 : (_object$service_time10 = object.service_time) === null || _object$service_time10 === void 0 ? void 0 : _object$service_time10.split(':')[1]) || 0) : 0;
  }, 0);
  var formattedNumber = function formattedNumber(num) {
    return ('0' + num).slice(-2);
  };
  hours += Math.floor(minutes / 60);
  minutes = minutes % 60;
  return Number(formattedNumber(hours)) * 60 + Number(formattedNumber(minutes));
}
function getTotalCost(items) {
  return (items === null || items === void 0 ? void 0 : items.reduce(function (accumulator, object) {
    return accumulator + Number(object === null || object === void 0 ? void 0 : object.cost);
  }, 0)) || 0;
}
function formatDate(date) {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();
  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;
  return [year, month, day].join('-');
}
function TimeDifferenceByD(date) {
  var date2 = new Date(date);
  var date1 = new Date();
  var Difference_In_Time = date2.getTime() - date1.getTime();
  var Difference_In_Days = parseFloat(Difference_In_Time / (1000 * 3600 * 24)).toFixed(0);
  function getFormatedStringFromDays(numberOfDays) {
    var days = Math.floor(numberOfDays % 365 % 30);
    return Math.abs(days) + (Math.abs(days) > 2 && Math.abs(days) <= 10 ? ' أيام ' : ' يوم ');
  }
  return getFormatedStringFromDays(Difference_In_Days);
}
function TimeDifferenceByM(date1, date2) {
  var startDate = new Date(date1);
  var endDate = new Date(date2);
  var startYear = startDate.getFullYear();
  var startMonth = startDate.getMonth();
  var endYear = endDate.getFullYear();
  var endMonth = endDate.getMonth();
  var monthCount = (endYear - startYear) * 12 + (endMonth - startMonth);
  return monthCount;
}
function TimeDifferenceByYMD(date) {
  var date1 = new Date(date);
  var date2 = new Date();
  var Difference_In_Time = date2.getTime() - date1.getTime();
  var Difference_In_Days = parseFloat(Difference_In_Time / (1000 * 3600 * 24)).toFixed(0);
  function getFormatedStringFromDays(numberOfDays) {
    var years = Math.floor(numberOfDays / 365);
    var months = Math.floor(numberOfDays % 365 / 30);
    var days = Math.floor(numberOfDays % 365 % 30);
    var result = (years > 0 ? years + (years > 2 && years <= 10 ? ' سنوات' : ' سنة') : '') + (months > 0 ? ' ' + months + (months > 2 && months <= 10 ? ' أشهر' : ' شهر') : '') + (days > 0 ? ' ' + days + (days > 2 && days <= 10 ? ' أيام' : ' يوم') : '');
    return result;
  }
  return removeColon(getFormatedStringFromDays(Difference_In_Days));
}
function convertDaysToYYMMDD(numberOfDays) {
  var years = Math.floor(numberOfDays / 365);
  var months = Math.floor(numberOfDays % 365 / 30);
  var days = Math.floor(numberOfDays % 365 % 30);
  var result = (years > 0 ? years + (years > 2 && years <= 10 ? ' سنوات ' : ' سنة ') : '') + (months > 0 ? months + (months > 2 && months <= 10 ? ' شهور ' : 'شهر ') : '') + (days > 0 ? days + (days > 2 && days <= 10 ? 'أيام ' : ' يوم ') : '');
  return removeColon(result);
}
function TimeDifferenceByMDH(date) {
  var companyTimezone = userService.getCompanyTimezone();
  var date_1 = dayjs(date).tz(companyTimezone);
  var date_2 = dayjs().tz(companyTimezone);
  var diffInMs = date_2 - date_1;
  // Calculate the difference in years
  var diffInYears = diffInMs / (1000 * 60 * 60 * 24 * 365);

  // Get the remaining months
  var remainingMonths = Math.floor((diffInYears - Math.floor(diffInYears)) * 12);

  // Get the remaining days
  var remainingDays = Math.floor((diffInYears - Math.floor(diffInYears)) * 365) % 30;

  // Get the remaining hours
  var remainingHours = Math.floor(diffInMs / (1000 * 60 * 60) % 24);

  // Format the output
  var output = '';
  if (Math.floor(diffInYears) > 0) {
    output += "".concat(Math.floor(diffInYears), "\u0633: ");
  }
  if (remainingMonths > 0) {
    output += "".concat(remainingMonths, "\u0634: ");
  }
  if (remainingDays > 0) {
    output += "".concat(remainingDays, "\u064A: ");
  }
  if (remainingHours > 0) {
    output += "".concat(remainingHours, "\u0633");
  }
  return removeColon(output);
}
function secondsToYMDHM(seconds) {
  seconds = Number(seconds);
  var d = Math.floor(seconds / (3600 * 24));
  var h = Math.floor(seconds % (3600 * 24) / 3600);
  var m = Math.floor(seconds % 3600 / 60);
  var dDisplay = d > 0 ? d + 'ي:' : '';
  var hDisplay = h > 0 ? h + 'س:' : '';
  var mDisplay = m > 0 ? m + 'د' : '';
  return removeColon(dDisplay + hDisplay + mDisplay);
}
function getHM(seconds) {
  var totalMinutes = Math.round(seconds / 60); // convert hours to minutes and round
  var hours = Math.floor(totalMinutes / 60); // get total hours by dividing total minutes by 60 and rounding down
  var minutes = totalMinutes % 60; // get the remaining minutes after getting the hours
  return {
    hours: hours,
    minutes: minutes
  };
}
function YearsToYearsMonthsDays(value) {
  var totalDays = value * 365;
  var years = Math.floor(totalDays / 365);
  var months = Math.floor((totalDays - years * 365) / 30);
  var days = Math.floor(totalDays - years * 365 - months * 30);
  var result = (years > 0 ? years + 'س' : '') + (months > 0 ? ' ' + months + 'ش' : '') + (days > 0 ? ' ' + days + 'ي' : '');
  return removeColon(result);
}
function numberWithCommas(x) {
  var _x$toString;
  return x === null || x === void 0 ? void 0 : (_x$toString = x.toString()) === null || _x$toString === void 0 ? void 0 : _x$toString.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}
function getVehicleAge(year) {
  var currentYear = new Date().getFullYear();
  return currentYear - Number(year) > 0 ? currentYear - Number(year) : null;
}
function ConvertHours(hours) {
  var units = {
    'س: ': 24 * 365,
    'ش: ': 24 * 30,
    'ي:': 24,
    س: 1
  };
  var result = [];
  for (var name in units) {
    var p = Math.floor(hours / units[name]);
    if (p == 1) result.push(p + name);
    if (p >= 2) result.push(p + name);
    hours %= units[name];
  }
  return result !== null && result !== void 0 && result.length ? result : '---';
}
function decimalToDaysHoursMinutes(decimalDays) {
  // Calculate the number of days
  var days = Math.floor(decimalDays);

  // Calculate the number of hours by subtracting the number of whole days and
  // multiplying the remainder by 24
  var hours = Math.floor((decimalDays - days) * 24);

  // Calculate the number of minutes by subtracting the number of whole hours and
  // multiplying the remainder by 60
  var minutes = Math.floor(((decimalDays - days) * 24 - hours) * 60);

  // Return the result as a string
  return removeColon("".concat(days > 0 ? days + 'ي:' : '').concat(hours > 0 ? hours + 'س:' : '').concat(minutes > 0 ? minutes + 'د:' : '').replace(/:$/, ''));
}
function convertToStringDate(dateString) {
  var date = new Date(dateString);
  var options = {
    weekday: 'short',
    day: 'numeric',
    month: 'numeric',
    year: 'numeric'
  };
  var formattedDate = date.toLocaleDateString('ar-CA', options);
  return formattedDate === null || formattedDate === void 0 ? void 0 : formattedDate.replaceAll('/', '-');
}
function convertMapsEventDateFormat(date) {
  var now = dayjs().tz(userService.getCompanyTimezone());
  var targetDate = dayjs(date).tz(userService.getCompanyTimezone());

  // Check if the date is today
  var isToday = targetDate.isSame(now, 'day');

  // Format the time (e.g., "09:00am")
  var formatTime = function formatTime(d) {
    return d.format('hh:mm a');
  };
  if (isToday) {
    var _formatTime, _formatTime$replace;
    // If it's today, show "Today" with the time
    return "\u0627\u0644\u064A\u0648\u0645, ".concat((_formatTime = formatTime(targetDate)) === null || _formatTime === void 0 ? void 0 : (_formatTime$replace = _formatTime.replace('am', 'ص')) === null || _formatTime$replace === void 0 ? void 0 : _formatTime$replace.replace('pm', 'م'));
  } else {
    // If it's not today, show the day, date, and time
    return convertDatesToTimezone(targetDate, 'ddd, DD-MM-YYYY, mm: hh a'); /* + formatTime(targetDate); */
  }
}